import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import Seo from "../components/seo"

import {
  FadeInRight,
  FadeInTop,
  FadeInTopList,
  useScrollImageReveal,
} from "../components/animations"
import Markdown from "marked-react"
import EmpowerBrandsBlock from "../components/EmpowerBrandsBlock"

import PartnerLogoTSC from "../assets/images/partner-logo-tsc.svg"
import PartnerLogoTSK from "../assets/images/partner-logo-tsk.svg"
import { graphql, useStaticQuery } from "gatsby"
import { PortableText } from "@portabletext/react"
import slugify from "slugify"

const descriptionSerializerComponents = {
  block: {
    normal({ children }) {
      return <p>{children}</p>
    },
  },
}

const WorkPage = () => {
  useScrollImageReveal()
  const pageCMSData = useStaticQuery(graphql`
    query WorkPageCMSData {
      sanityPageWork {
        title
      }

      SEOData: sanityPageWork {
        SEO {
          title: seoTitle
          description: seoDescription
        }
      }

      allSanityExpertise {
        nodes {
          title
          services
          _rawDescription
        }
      }
    }
  `)

  const expertiseData = pageCMSData.allSanityExpertise.nodes
  const SEOData = pageCMSData.SEOData.SEO

  return (
    <Layout>
      <Seo title={SEOData.title} description={SEOData.description} />
      <IntroSection data={pageCMSData.sanityPageWork.title} />
      <Expertise1 data={expertiseData[0]} />
      <Expertise2 data={expertiseData[1]} />
      <Expertise3 data={expertiseData[2]} />
      <PartnersSection />
      <SendUsAMailSection />
    </Layout>
  )
}

const IntroSection = ({ data }) => {
  return (
    <section className="c-workPage-hero grid relative">
      <div className="c-workPage-hero__bg relative h-full">
        <StaticImage
          src="../assets/images/work-hero-background.jpg"
          objectFit="cover"
          objectPosition="top"
          placeholder="blurred"
          className="h-full"
        />
        <div className="c-workPage-hero__fade"></div>
      </div>
      <FadeInTop>
        <h1 className="c-workPage-hero__heading z-30 lg:w-11/12 lg:mx-auto font-headings leading-tight text-4xl md:text-5xl lg:text-6xl pl-4 lg:pl-0 pt-3 md:pt-5">
          <span className="block w-min">{data}</span>
        </h1>
      </FadeInTop>
    </section>
  )
}

const ServiceDescriptionBlock = ({
  data: { title, number, _rawDescription: description },
}) => {
  return (
    <section
      id={slugify(title, { lower: true, strict: true })}
      className="c-container-full lg:flex py-12 lg:py-24 lg:pb-[400px]"
    >
      <div className="lg:w-[40%] shrink-0">
        <FadeInTop>
          <h2 className="max-w-[320px] md:max-w-[380px] lg:max-w-[520px] text-3xl md:text-4xl lg:text-5xl xl:text-6xl lg:leading-tight xl:leading-tight font-headings ">
            {title}
          </h2>
        </FadeInTop>
        <FadeInTop delay={0.2}>
          <span className="block text-[#666] text-3xl md:text-4xl lg:text-5xl xl:text-6xl lg:leading-tight font-headings mb-12 mt-1 lg:mt-6 ">
            {number}
          </span>
        </FadeInTop>
      </div>
      <div className="space-y-6 text-lg text-justify md:max-w-[680px] lg:ml-12">
        <PortableText
          value={description}
          components={descriptionSerializerComponents}
        />
      </div>
    </section>
  )
}

const Expertise1 = ({ data }) => {
  const serviceDescriptionData = {
    number: "01",
    ...data,
  }

  return (
    <>
      <ServiceDescriptionBlock data={serviceDescriptionData} />
      <div class="bg-white">
        <EmpowerBrandsBlock
          backgroundImage={
            <StaticImage
              alt="vivien evboren photo"
              src="../assets/images/empower-brands-veev-alt.jpg"
              objectPosition="top"
              objectFit="cover"
              className="h-full"
            />
          }
        />
        <ServiceListBlock serviceList={serviceDescriptionData.services} />
      </div>
    </>
  )
}

const Expertise2 = ({ data }) => {
  const serviceDescriptionData = {
    number: "02",
    ...data,
  }

  return (
    <>
      <ServiceDescriptionBlock data={serviceDescriptionData} />
      <div className="bg-white">
        <EmpowerBrandsBlock
          backgroundImage={
            <StaticImage
              alt="tife onifade photo"
              src="../assets/images/empower-brands-tife.jpg"
              objectPosition="top"
              objectFit="cover"
              className="h-full"
            />
          }
        />
      </div>
      <ServiceListBlock serviceList={serviceDescriptionData.services} />
    </>
  )
}

const Expertise3 = ({ data }) => {
  const serviceDescriptionData = {
    number: "03",
    ...data,
  }

  return (
    <>
      <ServiceDescriptionBlock data={serviceDescriptionData} />
      <div class="bg-black">
        <EmpowerBrandsBlock
          backgroundImage={
            <StaticImage
              alt="vivien evboren photo"
              src="../assets/images/empower-brands-veev.jpg"
              objectPosition="top"
              objectFit="cover"
              className="h-full"
            />
          }
        />
        <ServiceListBlock
          serviceList={serviceDescriptionData.services}
          variant="dark"
        />
      </div>
    </>
  )
}

const PartnersSection = () => {
  return (
    <section className="c-container-full pt-12 pb-24">
      <FadeInTop>
        <h2 className="font-headings text-3xl md:text-4xl lg:text-5xl leading-tight md:leading-tight lg:leading-tight mb-12 lg:mb-20">
          Our Stratecution <br /> Partners
        </h2>
      </FadeInTop>
      <div className="flex h-9 lg:h-12 space-x-8 lg:space-x-24">
        <FadeInTop>
          <a href="https://tscdesign.co" target="_blank">
            <img
              className="h-full w-auto"
              src={PartnerLogoTSC}
              alt="The Sole Company Logo"
            />
          </a>
        </FadeInTop>
        <FadeInTop delay={0.3}>
          <a href="https://tsk.pixieset.com/" target="_blank">
            <img
              className="h-full w-auto"
              src={PartnerLogoTSK}
              alt="The Seyi Kehinde Logo"
            />
          </a>
        </FadeInTop>
      </div>
    </section>
  )
}

const SendUsAMailSection = () => {
  return (
    <section className="bg-white text-black">
      <div className="lg:mx-auto lg:w-11/12 pt-12 lg:py-24 lg:flex justify-between">
        <div class="shrink-0 mb-12 px-4 lg:px-0">
          <FadeInTop>
            <h3 className="font-headings2 text-3xl leading-tight mb-12">
              Do you need us?
            </h3>
          </FadeInTop>
          <FadeInRight>
            <a
              href="mailto:hello@braudit.co"
              className="anim-elastic-line anim-elastic-line--dark text-lg"
            >
              Send us a mail
            </a>
          </FadeInRight>
        </div>
        <div class="js-img-reveal">
          <StaticImage
            src="../assets/images/ek-ball-up.jpg"
            height={495}
            width={650}
            className="h-[535px] w-full lg:w-[500px] xl:w-[650px] lg:h-auto"
            objectFit="cover"
            objectPosition="top center"
          />
        </div>
      </div>
    </section>
  )
}

const ServiceListBlock = ({ serviceList, variant }) => {
  let isDark = variant === "dark"

  return (
    <div className={`${isDark ? "bg-dark text-white" : "bg-white text-black"}`}>
      <div className="c-container-full md:flex py-12 lg:pb-24 lg:pt-12 lg:mt-[-245px]">
        <FadeInTop>
          <h3 className="lg:w-2/5 shrink-0 text-xl md:text-2xl lg:text-3xl text-[#999] mb-6">
            Services we offer
          </h3>
        </FadeInTop>
        <ul className="md:max-w-[680px] space-y-3 lg:space-y-4 text-lg lg:text-2xl leading-normal lg:leading-normal list-disc list-outside px-5 lg:pl-5 lg:pr-0 md:ml-9 lg:ml-0">
          <FadeInTopList>
            {serviceList.map(service => (
              <li>{service}</li>
            ))}
          </FadeInTopList>
        </ul>
      </div>
    </div>
  )
}

ServiceListBlock.propTypes = {
  variant: PropTypes.oneOf(["dark", "light"]),
  serviceList: PropTypes.array,
}

ServiceListBlock.defaultProps = {
  variant: "light",
}

export default WorkPage
