import React, { useEffect, useRef } from "react"
import { getRandomInt } from "../helpers/utils"

import { gsap } from "./animations"

const EmpowerBrandsBlock = ({ backgroundImage }) => {
  let container = useRef(null)
  let imgRef = useRef(null)

  useEffect(() => {
    let ps = container.current.querySelectorAll("p")

    // text entrance animations
    gsap
      .timeline({
        ease: "power1.out",
        scrollTrigger: {
          trigger: container.current,
          start: "40% bottom",
        },
      })
      .from(ps[0], {
        opacity: 0,
        xPercent: -40,
        duration: 0.7,
      })
      .from(ps[1], {
        opacity: 0,
        xPercent: 40,
        duration: 0.7,
      })
      .from(ps[2], {
        opacity: 0,
        xPercent: -40,
        duration: 0.7,
      })
      .from(ps[3], {
        opacity: 0,
        xPercent: 40,
        duration: 0.7,
      })

    // text scrub animations
    gsap
      .timeline({
        scrollTrigger: {
          trigger: container.current,
          scrub: 0.5,
          start: "20% bottom",
          end: "bottom top",
        },
      })
      .addLabel("start")
      .to(
        ps[0],
        {
          x: getRandomInt(50, 110),
        },
        "start"
      )
      .to(
        ps[1],
        {
          x: getRandomInt(-50, -80),
        },
        "start"
      )
      .to(
        ps[2],
        {
          x: getRandomInt(50, 110),
        },
        "start"
      )
      .to(
        ps[3],
        {
          x: getRandomInt(-50, -80),
        },
        "start"
      )

    // image parallax animation
    gsap
      .timeline({
        scrollTrigger: {
          trigger: container.current,
          start: "center center",
          end: "bottom top",
          scrub: 0.5,
        },
      })
      .to(imgRef.current, {
        yPercent: -8,
      })
  }, [])

  return (
    <div
      className="c-empower-brands overflow-hidden lg:w-11/12 mx-auto transform lg:-translate-y-[50%] relative w-full h-[250px] md:h-[350px] lg:h-[450px] xl:h-[600px]"
      ref={container}
    >
      <div ref={imgRef} className="js-image-container">
        {backgroundImage}
      </div>
      <p>empower</p>
      <p>brands</p>
      <p>people</p>
      <p>goals</p>
    </div>
  )
}

export default EmpowerBrandsBlock
